import React from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Seo from '../components/seo';
import Header from '../components/Header';
import Footer from '../components/footer';
import Layout from '../components/layout';
import '../sass/main.scss';
import ContentfulVetsNewsAndResearch from '../components/BlockLibrary/Veterinarians/ContentfulVetsNewsAndResearch';
import { VET_CATEGORY_NAME } from '../components/utilities';
import ContentfulVetsEducation from '../components/BlockLibrary/Veterinarians/ContentfulVetsEducation';
import ContentfulVetsLibrary from '../components/BlockLibrary/Veterinarians/ContentfulVetsLibrary';
import ContentfulVetsOurTeam from '../components/BlockLibrary/Veterinarians/ContentfulVetsOurTeam';

class VetsCategoryTemplate extends React.Component {
     constructor(props) {
          super(props);
     }

     componentDidMount() {
          const brower = typeof window !== `undefined`;

          if (brower) {
               var getOmValue = localStorage.getItem('Petinsurance_OM');
               this.props.setTrackingCode(getOmValue);
               var getPhoneValue = localStorage.getItem('Petinsurance_TrackingPhone');
               this.props.setTrackingPhone(getPhoneValue);
          }
          const trustpilotScript = document.createElement('script');
          trustpilotScript.type = 'text/javascript';
          trustpilotScript.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
          trustpilotScript.async = true
          document.head.appendChild(trustpilotScript)
          trustpilotScript.onload = function () {
               var trustbox = document.getElementsByClassName('trustpilot-widget');
               window.Trustpilot.loadFromElement(trustbox);
          };
     }

     render() {
          const category = get(this.props, 'data.contentfulVetsCategory');
          const path = get(this.props, '*');
          const trackingCodeDefault = get(this.props, 'data.getTrackingCodeDefault');
          const trackingCode = category.trackingCode != null ? category.trackingCode : trackingCodeDefault.trackingCode;

          const getMainContent = () => {
               switch (category.categoryName) {
                    case VET_CATEGORY_NAME.newsAndResearch:
                         return <ContentfulVetsNewsAndResearch data={category} />;
                    case VET_CATEGORY_NAME.library:
                         return <ContentfulVetsLibrary data={category} />;
                    case VET_CATEGORY_NAME.education:
                         return <ContentfulVetsEducation data={category} />;
                    case VET_CATEGORY_NAME.ourTeam:
                         return <ContentfulVetsOurTeam data={category} />;
                    case VET_CATEGORY_NAME.event:
                         return <>Events</>;
                    default:
                         return <ContentfulVetsNewsAndResearch data={category} />;
               }
          };
          return (
               <Layout>
                    <Seo
                         pageName={category.omniturePageNameLegacy || category.contentName}
                         specificPhone={category.specificPhone}
                         trackingCode={trackingCode}
                         title={category.seoTitleTag}
                         path={path}
                         canonical={category.seoCanonicalUrl ? category.seoCanonicalUrl.seoCanonicalUrl : ''}
                         description={category.seoMetaDescription ? category.seoMetaDescription.seoMetaDescription : ''}
                         robots={category.robotsDirective ? category.robotsDirective.robotsDirective : ''}
                         image={category.socialImage ? category.socialImage : category.featuredImage}
                         category={category}
                         hasBreadcrumb={true}
                         showAntiFlickerSnippet={category.showAntiFlickerSnippet}
                    />
                    <Header header={category.header}></Header>
                    <main id="main-content" role="main">
                         {getMainContent()}
                    </main>
                    <Footer footer={category.footer}></Footer>
               </Layout>
          );
     }
}

const mapDispatchToProps = (dispatch) => {
     return {
          setTrackingCode: (trackingCode) => dispatch({ type: `TRACKING_CODE`, payload: trackingCode }),
          setTrackingPhone: (trackingPhone) => dispatch({ type: `TRACKING_PHONE`, payload: trackingPhone })
     };
};
const mapStateToProps = (state) => {
     return {
          state
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(VetsCategoryTemplate);

export const pageQuery = graphql`
     query VetsCategoryBySlug($slug: String!) {
          site {
               siteMetadata {
                    title
               }
          }
          contentfulVetsCategory(slug: { eq: $slug }) {
               ...ContentfulVetsCategoryFields
          }
          getTrackingCodeDefault: contentfulPage(contentful_id: { eq: "wChsWYdrCJ5doI8QdVkBk" }) {
               id
               trackingCode
          }
     }
`;
